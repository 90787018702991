<template>
<WatchList :widget="widget" :data="data" :editable="true" @reloadWatchlists="handleWatchlists" :key="componentKey"></WatchList>
</template>

<script>
//	 این ویو برای ماژول فهرست ها (واچ لیست) در داشبورد مورد استفاده قرار میگیرد
import WatchList from '@/components/WatchList.vue'

export default {
    name: 'WatchListsView',
    components: {
        WatchList,
    },
    data: function () {
        return {
            widget: {},
            data: {},
            componentKey: 0,
        }
    },
    // در متد مانت یک فانکشی رو از هلپر کال میکنیم جهت لود کردن داده های ویجت واچ لیست
    mounted() {
        // در متد مانت یک فانکشی رو از هلپر کال میکنیم جهت لود کردن داده های ویجت 
        this.init();
    },
    methods: {
        init() {
            this.$helpers.getWidgets({ widget_type: 'watchlist', store: false }).then((widgets) => {
                let widget = widgets[0];

                this.widget = widget.widget;
                this.data = widget.data;
                this.componentKey += 1;
            });
        },
        handleWatchlists() {
            this.init();
        },
    },
}
</script>
